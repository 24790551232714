exports.components = {
  "component---src-components-blog-post-js-content-file-path-blog-20211011-hello-world-index-mdx": () => import("./../../../src/components/BlogPost.js?__contentFilePath=/home/runner/work/pimentel.page/pimentel.page/blog/20211011-hello-world/index.mdx" /* webpackChunkName: "component---src-components-blog-post-js-content-file-path-blog-20211011-hello-world-index-mdx" */),
  "component---src-components-blog-post-js-content-file-path-blog-20211016-the-mistery-of-the-changing-width-index-mdx": () => import("./../../../src/components/BlogPost.js?__contentFilePath=/home/runner/work/pimentel.page/pimentel.page/blog/20211016-the-mistery-of-the-changing-width/index.mdx" /* webpackChunkName: "component---src-components-blog-post-js-content-file-path-blog-20211016-the-mistery-of-the-changing-width-index-mdx" */),
  "component---src-components-blog-post-js-content-file-path-blog-20211205-enhanced-tables-index-mdx": () => import("./../../../src/components/BlogPost.js?__contentFilePath=/home/runner/work/pimentel.page/pimentel.page/blog/20211205-enhanced-tables/index.mdx" /* webpackChunkName: "component---src-components-blog-post-js-content-file-path-blog-20211205-enhanced-tables-index-mdx" */),
  "component---src-components-blog-post-js-content-file-path-blog-20211215-github-actions-index-mdx": () => import("./../../../src/components/BlogPost.js?__contentFilePath=/home/runner/work/pimentel.page/pimentel.page/blog/20211215-github-actions/index.mdx" /* webpackChunkName: "component---src-components-blog-post-js-content-file-path-blog-20211215-github-actions-index-mdx" */),
  "component---src-components-blog-post-js-content-file-path-blog-20220115-ssgs-index-mdx": () => import("./../../../src/components/BlogPost.js?__contentFilePath=/home/runner/work/pimentel.page/pimentel.page/blog/20220115-ssgs/index.mdx" /* webpackChunkName: "component---src-components-blog-post-js-content-file-path-blog-20220115-ssgs-index-mdx" */),
  "component---src-components-blog-post-js-content-file-path-blog-20230708-gatsby-update-index-mdx": () => import("./../../../src/components/BlogPost.js?__contentFilePath=/home/runner/work/pimentel.page/pimentel.page/blog/20230708-gatsby-update/index.mdx" /* webpackChunkName: "component---src-components-blog-post-js-content-file-path-blog-20230708-gatsby-update-index-mdx" */),
  "component---src-components-blog-post-js-content-file-path-blog-20231014-fixed-gatsby-update-index-mdx": () => import("./../../../src/components/BlogPost.js?__contentFilePath=/home/runner/work/pimentel.page/pimentel.page/blog/20231014-fixed-gatsby-update/index.mdx" /* webpackChunkName: "component---src-components-blog-post-js-content-file-path-blog-20231014-fixed-gatsby-update-index-mdx" */),
  "component---src-components-blog-post-js-content-file-path-blog-20231018-azure-sdk-otel-index-mdx": () => import("./../../../src/components/BlogPost.js?__contentFilePath=/home/runner/work/pimentel.page/pimentel.page/blog/20231018-azure-sdk-otel/index.mdx" /* webpackChunkName: "component---src-components-blog-post-js-content-file-path-blog-20231018-azure-sdk-otel-index-mdx" */),
  "component---src-components-post-list-js": () => import("./../../../src/components/PostList.js" /* webpackChunkName: "component---src-components-post-list-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */)
}

